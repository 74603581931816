<template>
  <div>
    <b-table
      :data="versions"
      class="tab-table-layout"
      detailed
      detail-key="id"
    >
      <b-table-column
        v-slot="{ row }"
        label="Name"
        field="name"
        width="40%"
      >
        <strong> Version tag: {{ row.name }} </strong>
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        label="Author"
        field="author"
      >
        {{ row.author }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        label="Environment"
        field="environment"
      >
        {{ row.environment }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        label=""
        cell-class="has-text-right"
      >
        <b-button
          size="is-small"
          class="app-dropdown-btn"
          @click.stop="toggleActionDropdown(`${row.id}${row.environment}`)"
        >
          <b-icon
            icon="dots-horizontal"
            size="is-size-6"
            class="app-dropdown-icon"
          />
        </b-button>
        <b-dropdown
          :ref="`versionActionDropdown${row.id}${row.environment}`"
          aria-role="list"
          position="is-bottom-left"
          class="app-action-dropdown"
          append-to-body
        >
          <b-dropdown-item
            :disabled="!row.is_deployed && !row.deployed_node"
            aria-role="listitem"
            class="is-flex is-align-items-center"
            @click="confirmRevert(row)"
          >
            <b-icon
              icon="backup-restore"
              type="is-black"
              custom-size="mdi-22px"
              class="mr-2"
            />
            Restore this version on development
          </b-dropdown-item>
        </b-dropdown>
      </b-table-column>
      <template #detail="{ row }">
        <h1 class="is-size-6 mb-2">
          Change log:
        </h1>
        <p
          class="change_log"
          v-html="row.change_log"
        />
      </template>
    </b-table>
  </div>
</template>

<script >
// libs
import { getCurrentInstance, onMounted, ref } from '@vue/composition-api'; // services

import { revertModuleService } from '@/services/application-service/moduleRequests';
import { fetchModuleVersionsService } from '@/services/version-control-service/versionControlModuleRequests';
import { fetchModuleTagsService } from '@/services/version-control-service/versionControlModuleRequests'; // composables

import { useRoute } from '@/hooks/vueRouter';
import { useBuefy } from '@/hooks/buefy';
import { convertModuleVersionUTCToCestTime } from '@/helpers/util'; // @VUE3: do not use this function when migrating to vue 3

const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const vm = getCurrentInstance(); //-- use composables --//

  const route = useRoute();
  const buefy = useBuefy(); //-- module versions logic --//

  const versions = ref([]);

  const fetchModuleVersions = async () => {
    try {
      const {
        moduleId
      } = route.params;
      const {
        data: {
          data
        }
      } = await fetchModuleVersionsService(moduleId);
      versions.value = data.map(e => {
        e.name = convertModuleVersionUTCToCestTime(e.name);
        return e;
      });
    } catch (err) {
      console.error(err);
    }
  };

  onMounted(async () => {
    await fetchModuleVersions();
  }); //-- table actions logic --//

  const toggleActionDropdown = rowId => {
    // @VUE3: use functional template ref in vue 3 instead (check offical vue 3 docs for info), vue 2 composition api plugin doesn't support them
    vm.proxy.$refs[`versionActionDropdown${rowId}`].toggle();
  }; //-- module revert logic --//


  const confirmRevert = async version => {
    const {
      appId,
      moduleId
    } = route.params; // Checking is there any non-deployed changes are in the development stage

    const {
      data: {
        data: currentVersions
      }
    } = await fetchModuleTagsService(moduleId);
    let message;
    let cnfrmTxt;

    if (currentVersions?.length && !currentVersions[0].is_deployed) {
      message = `Non-deployed changes will be discarded if you restore this version (${version.name}).
        In case you do not want to discard non-deployed changes, please press cancel and deploy your non-deployed changes first.
        You will still be able to restore the version you selected.`;
      cnfrmTxt = 'Proceed(discard non-deployed changes)';
    } else {
      message = `Are you sure you want to restore this ${version.name} version on development?`;
      cnfrmTxt = 'Proceed';
    }

    buefy.dialog.confirm({
      message,
      cancelText: 'Cancel',
      confirmText: cnfrmTxt,
      onConfirm: () => {
        revertModule(appId, moduleId, version.id);
      }
    });
  };

  const revertModule = async (applicationId, moduleId, versionId) => {
    try {
      await revertModuleService(applicationId, moduleId, {
        moduleVersionId: versionId
      });
      buefy.toast.open('Module restored successfully.');
    } catch (err) {
      console.error(err);
    }
  };

  return {
    versions,
    toggleActionDropdown,
    confirmRevert
  };
};

export default __sfc_main;
</script>

<style lang="scss" scoped>
.list:hover {
  opacity: 0.7;
  cursor: pointer;
}
</style>

<style lang="scss">
@import '~@/style/variables.scss';
@import '~@/style/components.scss';
.change_log ul {
  list-style: circle;
  margin-left: 20px;
}
</style>
